.customerName {
  font-size: 12px;
  font-weight: 400;
  color: #6200ea;
  text-align: center;
  text-decoration: underline;
  line-height: 32px;
}
.delete-items {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #ff1744;
  margin:0.5em 0 0 1em;
  cursor: pointer;
}
.orderStatus {
  font-size: 12px;
  font-weight: 500;
  color: #4f31e4;
  text-align: center;
  line-height: 16px;
  background: #f7f5ff;
  border-radius: 32px;
  padding: 2px 8px;
  border: 1px solid #bcb1f5;
}
.MuiFormControl-marginDense {
  margin-bottom: 3px !important;
}
.emptyPageContainer {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}
.customScrollBar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.customScrollBar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c7c7c8;
}
.customScrollBar::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.mt-24 {
  margin-top: 24px !important;
}
.edit-address-button {
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  display: flex;
  gap: 6px;
  border-bottom: 2px solid #00d659;
  width: fit-content;
}

.edit-address-button svg {
  height: 18px;
  width: 18px;
}

.filter-input-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #000000;
}

.date-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.order-status-checkbox {
  padding: 0 8px 0 0 !important;
}

.order-status-item {
  margin-left: 0 !important;
}

.order-status-item + .order-status-item {
  margin-top: 16px !important;
}

.order-status-item:nth-of-type(1) {
  margin-top: 8px !important;
}

.country-image-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.country-image-option img {
  width: 20px;
  height: 12px;
}

.edit-address-container-grid{
  gap: 16px;
}

.MuiFormControlLabel-label {
  font-size: 12px !important;
}