@charset "UTF-8";

@font-face {
  font-family: "studio-store";
  src:url("fonts/studio-store.eot");
  src:url("fonts/studio-store.eot?#iefix") format("embedded-opentype"),
    url("fonts/studio-store.woff") format("woff"),
    url("fonts/studio-store.ttf") format("truetype"),
    url("fonts/studio-store.svg#studio-store") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "studio-store" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "studio-store" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-download:before {
  content: "\76";
}
.icon-arrow-left:before {
  content: "\64";
}
.icon-arrow-right:before {
  content: "\6b";
}
.icon-bin-circle:before {
  content: "\77";
}
.icon-chevron-down:before {
  content: "\79";
}
.icon-chevron-large-down:before {
  content: "\7a";
}
.icon-chevron-large-left:before {
  content: "\43";
}
.icon-chevron-large-right:before {
  content: "\45";
}
.icon-chevron-large-up:before {
  content: "\46";
}
.icon-chevron-up:before {
  content: "\49";
}
.icon-circle:before {
  content: "\4c";
}
.icon-copy-circle:before {
  content: "\52";
}
.icon-cross:before {
  content: "\53";
}
.icon-download-1:before {
  content: "\61";
}
.icon-customer:before {
  content: "\62";
}
.icon-elipse-double:before {
  content: "\63";
}
.icon-eye:before {
  content: "\65";
}
.icon-footer:before {
  content: "\66";
}
.icon-grid:before {
  content: "\67";
}
.icon-magnifier:before {
  content: "\6c";
}
.icon-menu:before {
  content: "\6d";
}
.icon-message-text:before {
  content: "\6e";
}
.icon-notes-paper-text:before {
  content: "\6f";
}
.icon-notes-diary:before {
  content: "\70";
}
.icon-tablet-touch-1:before {
  content: "\71";
}
.icon-theme:before {
  content: "\72";
}
.icon-settings:before {
  content: "\41";
}
.icon-settings-circle:before {
  content: "\42";
}
.icon-wallet:before {
  content: "\44";
}
.icon-video-player:before {
  content: "\47";
}
.icon-user:before {
  content: "\4a";
}
.icon-union:before {
  content: "\4b";
}
.icon-tick:before {
  content: "\4d";
}
.icon-orders:before {
  content: "\4e";
}
.icon-pencil:before {
  content: "\4f";
}
.icon-plan:before {
  content: "\50";
}
.icon-website:before {
  content: "\51";
}
.icon-warning:before {
  content: "\55";
}
.icon-star:before {
  content: "\56";
}
.icon-tick-1:before {
  content: "\57";
}
.icon-new-tab:before {
  content: "\48";
}
.icon-bin-circle-1:before {
  content: "\68";
}
.icon-list:before {
  content: "\75";
}
.icon-logout:before {
  content: "\78";
}
.icon-school-teacher:before {
  content: "\54";
}
.icon-selection:before {
  content: "\58";
}
.icon-header:before {
  content: "\69";
}
.icon-plus:before {
  content: "\6a";
}
.icon-products:before {
  content: "\73";
}
.icon-arrow-right-1:before {
  content: "\74";
}
.icon-arrow-up:before {
  content: "\59";
}
.icon-files-o:before {
  content: "\5a";
}
.icon-clone:before {
  content: "\30";
}
