.customers-filter-bar > div > .MuiInputBase-fullWidth > .MuiOutlinedInput-notchedOutline {
  background-color: #fff !important;
}

.customers-filter-bar > div > .MuiInputBase-fullWidth {
  background: none !important;
}

.customers-filter-bar > div > .MuiInputBase-fullWidth > .MuiInputBase-input {
  top: -3px;
  background-color: #fff;
  z-index: 1;
  margin-right: 2px;
  margin-left: 2px;
  padding: 0.8rem 0.5rem;
}