.MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 16px 24px;
}

.MuiIconButton-label {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: inherit;
}

.notification-create-dialog {
    padding: 0 24px 55px !important;
}

.h6-user-confirmation {
    color: #000 !important;
}

.confirm-message {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #3c3e49;
    margin-left: -13px;
}

.Component-closeButton-user-confirmation {
    color: #000 !important;
    right: 40px !important;
    padding: 0 !important;
    position: absolute !important;
}

.MuiIconButton-root {
    flex: 0 0 auto;
    color: #0000008a;
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
}

.MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: #0000;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: #0000;
}

.Component-closeButton-user-confirmation {
    color: #000;
    right: 40px;
    padding: 0;
    position: absolute;
}

.MuiPaper-root {
    color: #676B7E;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
}

.Component-actionsRoot-user-confirmation {
    padding: 0 !important;
    border-top: 1px solid #e8e8e8 !important;
}

.MuiDialogActions-root {
    flex: 0 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: flex-end;
}

.Component-cancelButton-user-confirmation {
    color: #3c3e49;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    text-align: center;
    align-items: center;
    font-weight: 400;
    line-height: 20px;
    text-transform: none;
    text-decoration-line: underline;
}

.MuiButton-text {
    padding: 6px 8px;
}

.Component-cancelButton-user-confirmation {
    padding-right: 23px !important;
    color: #3c3e49 !important;
    cursor: pointer !important;
    display: flex !important;
    font-size: 12px !important;
    text-align: center !important;
    align-items: center !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-transform: none !important;
    text-decoration-line: underline !important;
}

.Component-confirmButton-user-confirmation {
    color: #000 !important;
    cursor: pointer !important;
    display: flex !important;
    padding: 20px 48px !important;
    background: #00d659 !important;
    background-color: #00d659 !important;
    align-items: center !important;
    border-radius: 3px !important;
    flex-direction: row !important;
    text-transform: none !important;
}

@media (min-width: 960px) {
    .MuiTypography-h6 {
        font-size: 1.25rem;
    }
}

@media (min-width: 600px) {
    .MuiTypography-h6 {
        font-size: 1.25rem;
    }
}

.MuiTypography-h6 {
    font-size: 1.125rem;
    font-family: Rubik;
    font-weight: 500;
    line-height: 1.6;
}

.MuiTypography-root {
    margin: 0;
}

.Component-dialogPaper-user-confirmation {
    border-radius: 10px !important;
}

@media (max-width: 599.95px) {
    .Component-dialogPaper-user-confirmation {
        width: 100% !important;
    }
}

.Component-dialogPaper-user-confirmation {
    min-width: 40% !important;
}

.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
}

.MuiDialog-container {
    height: 100%;
    outline: 0;
}

.MuiDialog-scrollPaper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiBackdrop-root {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: -1;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: #00000080;
    -webkit-tap-highlight-color: #0000;
}