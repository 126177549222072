.trans-fl-pg-mb-30 {
  margin-bottom: 30px;
}

.trans-fl-ttl {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  margin-top: 48;
  margin-bottom: 12;
  color: #000000;
}
.trans-fl-text {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* Blue Gray / 8 */

  color: #83889e;
}

.yt-transaction-cl-icn {
  max-height: 135px;
  width: auto;
}
.trans-fl-pg-inner-wrap {
  background: #ffffff;
  /* Blue Gray / 2 */
  width: "100%";
  height: "100%";

  border: 2px solid #ecedf0;
}
